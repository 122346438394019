import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import GatsbyImage from '../../components/gatsby-image';
import SocialProfile from '../../components/social-profile/social-profile';
import {
  IoLogoFacebook,
  IoLogoTwitter,
  IoLogoInstagram,
  IoLogoLinkedin,
} from 'react-icons/io';
import {
  AboutWrapper,
  AboutImage,
  AboutPageTitle,
  AboutDetails,
  SocialProfiles,
} from './style';

const SocialLinks = [
  {
    icon: <IoLogoFacebook />,
    url: 'https://www.facebook.com/',
    tooltip: 'Facebook',
  },
  {
    icon: <IoLogoInstagram />,
    url: 'https://www.instagram.com/',
    tooltip: 'Instagram',
  },
  {
    icon: <IoLogoTwitter />,
    url: 'https://twitter.com/',
    tooltip: 'Twitter',
  },
  {
    icon: <IoLogoLinkedin />,
    url: 'https://www.linkedin.com/',
    tooltip: 'Linked In',
  },
];

interface AboutProps {}

const About: React.FunctionComponent<AboutProps> = () => {
  const Data = useStaticQuery(graphql`
    query {
      avatar: file(absolutePath: { regex: "/about.jpg/" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      site {
        siteMetadata {
          author
          about
        }
      }
    }
  `);

  return (
    <AboutWrapper>
      <AboutPageTitle>
        <h2>Lelieur</h2>
        <p>
        Lelieur BV (commercial name Shipdetection.eu)  develops energy and surveillance solutions.
        </p>
      </AboutPageTitle>

      <AboutImage>
        <GatsbyImage src={Data.avatar.childImageSharp.gatsbyImageData} alt="about" />
      </AboutImage>

      <AboutDetails>
        <h2>www.shipdetection.eu</h2>
        <p>
        Delivering actionable intelligence for any vessel: As a consortium lead of Copernicus Blue, a Horizon 2020 project, together with NSAS.aero and Vake.ai  we deliver near real-time vessel detections of non-cooperative and suspect ships to support customs and fisheries inspections as well as Coast Guard rescue missions and offshore asset protection surveillance. 

By using automated algorithms in satellite imagery, Radio Frequency and AIS data together with vessel databases it is now possible to collect intelligence and evidence of any activity happening near ports, shorelines, around submarine cables and offshore installations such as wind parks.  



        </p>
        <p>
        Through continuous monitoring and early warning alerts we provide a fast response by organizing guided plane surveillance with long range video streaming over a satellite connection. 
        </p>

        <SocialProfiles>
          <SocialProfile items={SocialLinks} />
        </SocialProfiles>
      </AboutDetails>
    </AboutWrapper>
  );
};

export default About;
